import qs from "qs";

import VueRouter from "vue-router";
// import SmsTemplate from "./views/sms_templates/SmsTemplate";

// import Services from "./views/wholesaler/Services";
// import Stocks from "./views/wholesaler/Stocks";

// let parse = query => {
//   return qs.parse(query);
// };

// let stringify = query => {
//   let result = qs.stringify(query);
//
//   return result ? "?" + result : "";
// };

const DefaultContainer = () => import("./containers/DefaultContainer.vue");

const Orders = () => import("./views/orders/Orders.vue");
const Order = () => import("./views/orders/Order.vue");
const NewOrder = () => import("./views/orders/NewOrder");

const Products = () => import("./views/products/Products.vue");
// const Product = () => import("./views/products/Product");

const Statuses = () => import("./views/statuses/Statuses.vue");
// const StatusTree = () => import("./views/statuses/StatusTree.vue");

// const DeliveryType = () => import("./views/delivery_types/DeliveryType");
const DeliveryTypes = () => import("./views/delivery_types/DeliveryTypes");

const Projects = () => import("./views/projects/Projects");
// const Project = () => import("./views/projects/Project");

const SmsProviders = () => import("./views/sms/SmsProviders");
const SmsProvider = () => import("./views/sms/SmsProvider");

const SmsSend = () => import("./views/sms/SmsSend");

const SmsTemplates = () => import("./views/sms_templates/SmsTemplates");

// const OrderAdvertSources = () => import("./views/advert_sources/AdvertSources")

const Unloads = () => import("./views/unloads/Unloads");
const Unload = () => import("./views/unloads/Unload");

const Geos = () => import("./views/geo/Geos");
// const Geo = () => import("./views/geo/Geo");

const Calls = () => import("./views/calls/Calls.vue");
const CallCenterCalls = () => import("./views/call_center_calls/CallCenterCalls");

const OrderSenders = () => import("./views/order_senders/OrderSenders.vue") ;

const ProjectCategories = () => import("./views/project_category/ProjectCategories");
// const ProjectCategory = () => import("./views/project_category/ProjectCategory");

const Organizations = () => import("./views/organizations/Organizations");

const Traffics = () => import("./views/traffics/Traffics");
// const Traffic = () => import("./views/traffics/Traffic");

const ProductCategories = () => import("./views/product_category/ProductCategories");
// const ProductCategory = () => import("./views/product_category/ProductCategory");

const CallCenters = () => import("./views/call_center/CallCenters");
// const CallCenter = () => import("./views/call_center/CallCenter");

const Currencies = () => import("./views/currency/Currencies");
// const Currency = () => import("./views/currency/Currency");

const Entities = () => import("./views/entities/Entities");
// const Entity = () => import("./views/entities/Entity");

const RoleGroups = () => import("./views/role_groups/RoleGroups");
// const RoleGroup = () => import("./views/role_groups/RoleGroup");

const AbnormalTrafficReport = () => import("./views/reports/AbnormalTrafficReport");
const LogisticCallsReport = () => import("./views/reports/LogisticCallsReport");
const ReportOfManualAndAutomaticRinging = () => import("./views/reports/ReportOfManualAndAutomaticRinging");
const SalesReport = () => import("./views/reports/SalesReport");
const SalesSectionReport = () => import("./views/reports/SalesSectionReport");
const SalesSectionV2Report = () => import("./views/reports/SalesSectionV2Report");
const CommonReport = () => import("./views/reports/CommonReport");
const FrodReport = () => import("./views/reports/FrodReport");
const AccountingReport = () => import("./views/reports/AccountingReport");
const LogisticReport = () => import("./views/reports/LogisticReport");
const FinesReport = () => import("./views/reports/FinesReport");
const CallCenterWagesExtendedReport = () => import("./views/reports/CallCenterWagesExtendedReport");
const CallCenterWagesExtendedReportV2= () => import("./views/reports/CallCenterWagesExtendedReportV2");
const CallCenterWagesExtendedReportV2Setup= () => import("./views/reports/setup/CallCenterWagesExtendedReportV2Setup");
const CallCenterWagesTimeOnLineReport = () => import("./views/reports/CallCenterWagesTimeOnLineReport");
const WholesaleSalesReport = () => import("./views/reports/WholesaleSalesReport");
const SurveyReport = () => import("./views/reports/SurveyReport");
const CallsMonitoringReport = () => import("./views/reports/CallsMonitoringReport");
const MedProjectReport = () => import("./views/reports/MedProjectReport");
const AverageTransitionTime = () => import("./views/reports/AverageTransitionTime");
const SmsLinkShortReport = () => import("./views/reports/SmsLinkShortReport");
const BpmAutoReport = () => import("./views/reports/BpmAutoReport");

const AdvertSources = () => import( "./views/advert_sources/AdvertSources");
const AtsSips = () => import("./views/ats/AtsSips");
const AtsServers = () => import("./views/ats/AtsServers");
const AtsGroups = () => import("./views/ats/AtsGroups");
const AtsUsers = () => import("./views/ats/AtsUsers");
const Providers = () => import("./views/providers/Providers");
const LogActivities = () => import("./views/log_activities/LogActivities.vue");
const OutRoutes = () => import("./views/ats/routes/OutRoutes");
const AtsQueues = () => import("./views/ats/queues/AtsQueues");
const AtsQueueGroups = () => import("./views/ats/queues/AtsQueueGroups");
const AtsMonitoring = () => import("./views/ats/monitoring/AtsMonitoring");

const Webinars = () => import("./views/webinars/Webinars");
const WebinarCourse = () => import("./views/webinars/WebinarCourse");
const WebinarCourseItem = () => import("./views/webinars/WebinarCourseItem");

const OrderValidator = () => import("./views/order_validator/OrderValidator");

const Copies = () => import("./views/copies/Copies");
const Copy = () => import("./views/copies/Copy");

const OrderTypes = () => import("./views/order_types/OrderTypes");

const Owners = () => import("./views/owners/Owners");

const WholesalerServices = () => import("./views/wholesaler/Services");
const WholesalerStocks = () => import("./views/wholesaler/Stocks");

const WholesalerOrders = () => import("./views/wholesaler/OptOrders/OptOrders");
const WholesalerOrder  = () => import("./views/wholesaler/OptOrders/OptOrder");
const NewOptOrder      = () => import("./views/wholesaler/OptOrders/NewOptOrder");

const WholesalerPurchases = () => import("./views/wholesaler/Purchases");
const WholesalerAcceptance = () => import("./views/wholesaler/Acceptance");

const WholesalerUnloads = () => import("./views/wholesaler/unloads/Unloads");
const WholesalerUnload = () => import("./views/wholesaler/unloads/Unload");

const WholesalerClients = () => import("./views/wholesaler/WholesalerClients");

const OptTrafficSources = () => import("./views/wholesaler/OptTrafficSources/OptTrafficSources");

const SaleStatuses = () => import("./views/wholesaler/SaleStatuses/SaleStatuses");

const AccordSend = () => import("./views/accord/AccordSend");
const OrderLoad = () => import("./views/order_load/OrderLoad");

const Otsledi = () => import("./views/otsledi/Otsledi.vue");

const QuantityProducts = () => import("./views/quantity_products/QuantityProducts");

const AuditOrders = () => import("./views/audit_orders/AuditOrders");
const FineCats = () => import("./views/audit/FineCats");
const ReasonFines = () => import("./views/audit/ReasonFines");
const Fines = () => import("./views/audit/Fines");
const Fine = () => import("./views/audit/Fine");
const NewFine = () => import("./views/audit/NewFine");

const PaymentTypes = () => import("./views/payment_types/PaymentTypes");
const Packed = () => import("./views/packed/Packed");
const PackedReport = () => import("./views/packed/PackedReport");
const PackedBarcode = () => import("./views/packed/PackedBarcode");
const WhoPacked = () => import("./views/packed/WhoPacked");
const PackedSpeedStandards = () => import("./views/packed_standards/PackedSpeedStandards");
const Unpacked = () => import("./views/packed/Unpacked");

const DeliveryDpdReport = () => import("./views/delivery_dpd/DeliveryDpdReport");

const Subdivisions = () => import("./views/subdivision/Subdivisions");

const CallsCheck = () => import("./views/telefony_check/CallsCheck");

const CallsState = () => import("./views/telefony_check/CallsState");
const SmsCheck = () => import("./views/sms_check/SmsCheck");
const SmsState = () => import("./views/sms_check/SmsState");

const HpBase = () => import("./views/hp_base/HpBase");

const RobotScenarios = () => import("./views/robot/RobotScenarios");
const RobotOrdersReport = () => import("./views/robot_report/RobotOrdersReport.vue");

const IncomingCallAlert = () => import("./views/sip_calls_crm/IncomingCallAlert.vue");

const Notifications = () => import("./views/notifications/Notifications.vue");
const Notification = () => import("./views/notifications/Notification.vue");
const Tags = () => import("./views/tags/Tags.vue");

const routes = [
  {
    path: "/",
    meta: { label: "Главная", label_en: "Main"},
    name: "main",
    component: DefaultContainer,
    children: [
      {
        path: "orders",
        meta: { label: "Заявки", label_en: "Orders"},
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "",
            name: "orders",
            meta: { label: "Список заявок", label_en: "Order list" },
            component: Orders
          },
          {
            path: ":key",
            meta: { label: "Редактирование заявки", label_en: "Order editing"  },
            name: "order-edit",
            component: Order
          }
        ]
      },
      {
        path: "new_order",
        meta: { label: "Новая заявка", label_en: "New order" },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "",
            name: "new_order",
            meta: { label: "Новая заявка", label_en: "New order" },
            component: NewOrder
          }
        ]
      },
      {
        path: "appointment_managers",
        name: "appointment_managers",
        meta: { label: "Назначение ответственных менеджеров", label_en:"Appointment of responsible managers" },
        component: () => import('@/views/orders/appointment_manager/AppointmentManagers.vue')
      },
      {
        path: "sms_providers",
        meta: { label: "Провайдеры sms", label_en: "SMS providers" },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "",
            name: "sms_providers",
            meta: { label: "Список sms-провайдеров", label_en: "SMS providers list" },
            component: SmsProviders
          },
          {
            path: ":id",
            meta: { label: "Редактирование sms-провайдера", label_en: "SMS provider editing" },
            name: "sms_provider_edit",
            component: SmsProvider
          }
        ]
      },
      {
        path: "sms_send",
        meta: { label: "Массовая отправка SMS", label_en:"Bulk sending SMS" },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "",
            meta: { label: "Массовая отправка SMS", label_en:"Bulk sending SMS" },
            name: "sms_send",
            component: SmsSend
          }
        ]
      },
      {
        path: "sms_templates",
        meta: { label: "Справочник шаблонов", label_en:"SMS templates" },
        name: "sms_templates",
        component: SmsTemplates
      },
      {
          path: "sms_check",
          meta: { label: "SMS Аномалии", label_en: "SMS Anomalies" },
          name: "sms_check",
          component: SmsCheck
      },
      {
          path: "sms_state",
          meta: { label: "SMS Статистика", label_en: "SMS Statistics" },
          name: "sms_state",
          component: SmsState
      },
      {
        path: "fine-cats",
        meta: { label: "Список категорий", label_en:"Category list" },
        name: "fine-cats",
        component: FineCats
      },
      {
        path: "reason-fines",
        meta: { label: "Причины штрафов", label_en:"Reasons for fines" },
        name: "reason-fines",
        component: ReasonFines
      },
      {
        path: "fines",
        meta: { label: "Штрафы", label_en:"Fines" },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "",
            name: "fines",
            meta: { label: "Список штрафов", label_en:"Fines list" },
            component: Fines
          },
          {
            path: ":id",
            meta: { label: "Редактирование штрафа", label_en: "Fine editing"  },
            name: "fine-edit",
            component: Fine
          }
        ]
      },
      {
        path: "new_fine",
        meta: { label: "Новый штраф", label_en:"New fine" },
        name: "new_fine",
        component: NewFine
      },
      {
        path: "notifications",
        meta: { label: "Уведомления", label_en:"Notifications" },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "",
            name: "notifications",
            meta: { label: "Уведомления", label_en:"Notifications" },
            component: Notifications
          },
          {
            path: ":id",
            meta: { label: "Уведомление", label_en: "Notification"  },
            name: "notification",
            component: Notification,
            props: route => ({
                id: String(route.params.id),
            }),
          }
        ]
      },
      {
        path: "products",
        meta: { label: "Продукты", label_en:"Products" },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "",
            name: "products",
            meta: { label: "Продукты", label_en:"Products" },
            component: Products
          },
          {
            path: "product_category",
            meta: { label: "Категории продуктов", label_en:"Product category" },
            name: "product_category",
            component: ProductCategories
          }
        ]
      },
      {
        path: "quantity-products",
        meta: { label: "Остатки по товарам" },
        name: "quantity_products",
        component: QuantityProducts
      },
      {
        path: "projects",
        meta: { label: "Проекты", label_en:"Projects" },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "",
            name: "Список проектов",
            meta: { label: "Список проектов", label_en:"Projects list" },
            component: Projects
          },
          {
            path: "project_category",
            meta: { label: "Категории проектов", label_en:"Projects Categories" },
            name: "project-category-list",
            component: ProjectCategories
          }
        ]
      },
      {
        path: "organizations",
        meta: { label: "Пользователи", label_en:"Users" },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: ":id?",
            meta: { label: "Список пользователей", label_en:"Users list" },
            name: "organization-list",
            component: Organizations,

          },
          {
              path: ":id/validator",
              meta: { label: "Валидатор заказов", label_en:"Order validator" },
              name: "order-validator",
              component: OrderValidator,
          },
        ]
      },
      {
          path: "tariffs",
          meta: { label: "Тарифы", label_en:"Tariffs" },
          name: "tariffs-list",
          component: () => import('@/views/organizations/TariffsTabs.vue')
      },
      {
          path: "balance",
          meta: { label: "Баланс", label_en:"Balance" },
          name: "balance-list",
          component: () => import('@/views/organizations/BalanceList.vue')
      },
      {
        path: "others",
        redirect: "/others/statuses",
        meta: { label: "Разное", label_en:"Others" },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "statuses",
            meta: { label: "Статусы", label_en:"Statuses" },
            name: "status-list",
            component: Statuses
          },
          {
            path: "delivery_types",
            meta: { label: "Типы доставки", label_en:"Delivery types" },
            name: "delivery-type-list",
            component: DeliveryTypes
          },
          {
            path: "unloads",
            meta: { label: "Выгрузки", label_en:"Unloads" },
            component: {
              render(c) {
                return c("router-view");
              }
            },
            children: [
              {
                path: "",
                meta: { label: "Список выгрузок", label_en:"Unloads list" },
                name: "unload-list",
                component: Unloads
              },
              {
                path: "unload/:id?",
                meta: { label: "Редактор выгрузки", label_en:"Unloads editor" },
                name: "unload",
                component: Unload
              }
            ]
          },
          {
            path: "copies",
            meta: { label: "Копирование заявок", label_en:"Copy orders" },
            component: {
              render(c) {
                return c("router-view");
              }
            },
            children: [
              {
                path: "",
                meta: { label: "Копирование заявок список", label_en:"List of copied orders" },
                name: "copies-list",
                component: Copies
              },
              {
                path: "copy/:id?",
                meta: { label: "Редактор копирование заявок", label_en:"Copied orders editor" },
                name: "copy-list",
                component: Copy
              }
            ]
          },
          {
            path: "order_types",
            meta: { label: "Типы обращений", label_en: "Types of appeal" },
            name: "order_types",
            component: OrderTypes
          },
          {
            path: "subdivisions",
            meta: { label: "Подразделения", label_en: "Subdivisions" },
            name: "subdivisions",
            component: Subdivisions
          },
          {
            path: "geos",
            meta: { label: "Гео", label_en: "Geo" },
            name: "geos",
            component: Geos
          },
          {
            path: "payment_types",
            meta: { label: "Способы оплаты", label_en:"Payment types" },
            name: "payment-type-list",
            component: PaymentTypes
          },
          {
            path: "traffics",
            meta: { label: "Трафик", label_en: "Traffics" },
            name: "traffics",
            component: Traffics
          },
          {
            path: "advert_sources",
            meta: { label: "Источники трафика", label_en:"Traffic sources" },
            name: "advert_sources",
            component: AdvertSources
          },
          {
            path: "call_center",
            meta: { label: "Колл-центры", label_en:"Call-centers" },
            name: "call_center",
            component: CallCenters
          },
          {
            path: "currency",
            meta: { label: "Валюта", label_en:"Currency" },
            name: "currency",
            component: Currencies
          },
          {
            path: "accord",
            meta: { label: "Аккорд" },
            name: "accord",
            component: AccordSend
          },
          {
            path: "order_load",
            meta: { label: "Загрузка заказов" },
            name: "order_load",
            component: OrderLoad
          },
          {
            path: "otsledi",
            meta: { label: "Отследи" },
            name: "otsledi",
            component: Otsledi
          },
          {
            path: "hp-base",
            meta: { label: "Справочник Х/П Базы", label_en: "C/R base directory"},
            name: "HpBase",
            component: HpBase
          },
          {
            path: "tags",
            meta: { label: "Теги", label_en: "Tags"},
            name: "tags",
            component: Tags
          },
          {
            path: "robot",
            redirect: { name: 'robots-list' },
            meta: {
              label: "Робот",
              label_en: "Robot",
            },
            name: "Robo",
            component: {
              render(c) {
                return c("router-view");
              }
            },
            children: [
              {
                path: "",
                meta: { label: "Список ботов", label_en: "Bot list" },
                name: "robots-list",
                component: () => import('@/views/robot/RobotList.vue'),
                props: (route) => ({
                  showTemplates: route.query.show_templates === 'true' ? true : false,
                }),
              },
              {
                path: "edit/:id",
                meta: {
                  label: "Редактор сценария бота",
                  label_en:"Robot script editor",
                },
                name: "robot-editor",
                component: () => import('@/views/robot/RobotPage.vue'),
                props: (route) => ({
                  id: String(route.params.id),
                  blackListTemp: route.params.blackListTemp,
                }),
              },
              {
                path: "create",
                meta: {
                  label: "Редактор сценария бота (новый)",
                  label_en:"Robot script editor (new)",
                },
                name: "robot-editor-create",
                component: () => import('@/views/robot/Robot.vue'),
                props: (route) => ({
                  id: String(route.params.id),
                  isNew: true,
                  action_id: Number(route.query.action_id),
                }),
              },
              {
                path: "templates",
                meta: {
                  label: "Шаблоны сообщений",
                  label_en: "Message templates",
                },
                name: "waba-templates",
                component: () => import('@/views/robot/whatsapp/TemplateList.vue'),
                props: (route) => ({
                  refresh: String(route.query.refresh || 'false'),
                }),
                children: [
                  {
                    path: "new",
                    meta: {
                      label: "Новый шаблон",
                      label_en: "New template",
                    },
                    name: "new-waba-template",
                    component: () => import('@/views/robot/whatsapp/TemplateNew.vue'),
                  },
                ],
              },
            ],
          },
        ]
      },
      {
        path: 'phrases',
        meta: { label: 'Фразы', label_en: 'Phrases' },
        name: 'phrases',
        component: () => import('@/views/others/phrases/PhraseList.vue'),
      },
      {
        path: "robot_projects",
        meta: { label: "Проекты роботов", label_en: "Robot projects"},
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children:[
          {
            path: "",
            meta: { label: "Проекты роботов", label_en: "Robot projects"},
            name: "robot_projects_list",
            component: () => import('@/views/robot/projects/RobotProjectList.vue')
          },
          {
            path: ":id",
            meta: { label: "Проект робота", label_en: "Robot project"},
            name: "robot_project",
            component: () => import('@/views/robot/projects/RobotProject.vue'),
            props: route => ({
              id: String(route.params.id),
            }),
            children: [
              {
                path: "dialogs/:did",
                meta: { label: "Диалог", label_en: "Dialog markups"},
                name: "dialog_markups",
                component: () => import('@/views/robot/projects/RobotProjectDialog.vue'),
                props: (route) => ({
                  did: String(route.params.did),
                }),
              },
            ],
          },
        ],
      },
      {
        path: "audit_orders",
        name: "Аудит заказов",
        component: AuditOrders,
        meta: { label: "Аудит заказов", label_en:"Audit Orders" },
      },
      {
        path: "calls",
        name: "Звонки",
        component: Calls,
        meta: { label: "Звонки", label_en:"Calls" },
      },
      {
        path: "kc_calls",
        name: "Звонки КЦ",
        component: CallCenterCalls,
        meta: { label: "Звонки  КЦ", label_en:"Calls CC" },
      },
      {
        path: "log_activities",
        name: "Логи",
        component: LogActivities,
        meta: { label: "Логи пользователей", label_en:"Users log" },
      },
      {
        path: "order_senders",
        meta: { label: "Отправители", label_en:"Orders senders" },
        name: "Отправители",
        component: OrderSenders
      },
      {
        path: "r_&_e",
        redirect: "/r_&_e/roles",
        meta: { label: "Роли и сущности", label_en:"Roles & entities" },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "roles",
            meta: { label: "Роли", label_en:"Roles" },
            name: "roles",
            component: RoleGroups
          },
          {
            path: "entities",
            meta: { label: "Сущности", label_en:"Entities" },
            name: "entities",
            component: Entities
          }
        ]
      },
      {
        path: '/leads',
        meta: { label: "Лиды", label_en: "Leads" },
        name: "leads",
        component: () => import('@/views/campaign_leads/CampaignLeads')
      },
      {
        path: '/chat',
        meta: { label: "Вмешаться в переписку", label_en: "Intervene in a conversation"},
        name: "MChat",
        component: () => import('@/views/chat/MChat.vue')
      },
      {
        path: 'integrations',
        meta: { label: "Интеграции", label_en: "Integrations"},
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children:[
          {
            path: "",
            meta: { label: "Интеграции", label_en: "Integrations"},
            name: "integrations",
            component: () => import('@/views/integrations/Integrations.vue'),
          },
          {
            path: "amocrm",
            name: "amocrm",
            meta: { label: "Amocrm", label_en: "Amocrm" },
            component: () => import('@/views/integrations/AmoCrm/AmoCrmIntegration.vue'),
          },
          {
            path: "bitrix24",
            meta: { label: "Битрикс24", label_en: "Bitrix24"},
            name: "bitrix24",
            component: () => import('@/views/integrations/Bitrix/BitrixIntegration.vue'),
          },
          {
            path: "hh",
            meta: { label: "HeadHunter", label_en: "HeadHunter"},
            name: "hh-integration",
            component: () => import('@/views/integrations/HH/HHIntegration.vue'),
          },
          {
            path: "custom",
            meta: { label: "Настраиваемая", label_en: "Custom"},
            name: "custom-integration",
            component: () => import('@/views/integrations/custom/CustomIntegration.vue'),
            children: [
              {
                path: ":id",
                meta: { label: "Редактирование", label_en: "Edit"},
                name: "custom-integration-event",
                component: () => import('@/views/integrations/custom/CustomIntegrationEvent'),
                props: (route) => ({
                  event: Number(route.params.event),
                  id: Number(route.params.id),
                }),
              },
            ],
          },
        ]
      },
      {
        path: 'hh',
        redirect: "/integrations/hh",
        meta: { label: "HeadHunter", label_en: "HeadHunter"},
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children:[
          {
            path: "resumes",
            meta: { label: "Поиск резюме", label_en: "Search for resumes"},
            name: "hh-resumes",
            component: () => import('@/views/integrations/HH/HHResumes.vue'),
          },
        ]
      },
      {
        path: '/documentation',
        meta: { label: "Справочная информация", label_en: "Documentation"},
        name: "documentation",
        component: () => import('@/views/support/SupportDocs.vue')
      },
      {
        path: '/tech_support',
        meta: { label: "Тех. поддержка", label_en: "Tech support"},
        name: "tech_support",
        component: () => import('@/views/support/SupportContacts.vue')
      },
      {
          path: "bpm",
          redirect: "/bpm/bp_list",
          meta: { label: "Бизнес-процессы", label_en:"Business process management" },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
              {
                  path: "bp_list",
                  meta: { label: "Бизнес-процессы", label_en:"Business process" },
                  component: {
                    render(c) {
                      return c("router-view");
                    }
                  },
                  children:[
                    {
                      path: "",
                      name: "bp_list",
                      meta: { label: "Список бизнес-процессов", label_en: "Business process list" },
                      component: () => import('@/views/bpm/bp/BpList.vue'),
                    },
                    {
                      path: ":id",
                      meta: { label: "Бизнес-процесс", label_en: "Business process"},
                      name: "bpm-bp",
                      component: () => import('@/views/bpm/bp/Bp.vue'),
                      props: route => ({
                          id: String(route.params.id),
                      }),
                    },
                    {
                      path: ":id/edit",
                      meta: { label: "Редактор", label_en: "Editor"},
                      name: "bpm-bp-edit",
                      component: () => import('@/views/bpm/bp/scheme/BpmEditor.vue'),
                      props: route => ({
                        id: String(route.params.id),
                        solution_id: Number(route.query.solution_id),
                      }),
                    },
                    {
                      path: ":id/view",
                      meta: { label: "Просмотр", label_en: "Viewer"},
                      name: "bpm-bp-view",
                      component: () => import('@/views/bpm/bp/scheme/BpmEditor.vue'),
                      props: route => ({
                        id: String(route.params.id),
                        canEdit: false,
                      }),
                    },
                  ]
              },
              {
                path: "db_list",
                meta: { label: "Базы контрагентов", label_en:"Countractors databases" },
                component: {
                  render(c) {
                    return c("router-view");
                  }
                },
                children:[
                  {
                    path: "",
                    name: "db-list",
                    meta: { label: "Список баз контрагентов", label_en: "Contractor database list" },
                    component: () => import('@/views/bpm/db/DbList.vue'),
                  },
                  {
                    path: ":id",
                    meta: { label: "База контрагентов", label_en: "Countractors database"},
                    name: "bpm-db",
                    component: () => import('@/views/bpm/db/Db.vue'),
                    props: route => ({
                        id: String(route.params.id),
                    }),
                  }
                ]
              },
              {
                path: "contrahen_list",
                meta: { label: "Контрагенты", label_en:"Countractors" },
                component: {
                  render(c) {
                    return c("router-view");
                  }
                },
                children:[
                  {
                    path: "",
                    name: "contrahen-list",
                    meta: { label: "Контрагенты", label_en: "Countractors" },
                    component: () => import('@/views/bpm/contrahens/ContrahenList.vue'),
                  },
                  {
                    path: ":id",
                    meta: { label: "Контрагент", label_en: "Контрагент"},
                    name: "contrahen",
                    component: () => import('@/views/bpm/contrahens/Contrahen.vue'),
                    props: route => ({
                        id: String(route.params.id),
                        bpm_db_key: String(route.params.bpm_db_key)
                    }),
                  }
                ]
              },
              {
                path: "bp_ats_setup",
                name: "bp_ats_setup",
                meta: { label: "Связь", label_en:"Communications" },
                component: () => import('@/views/bpm/bp/BpmBpAtsSetup.vue')
              },
              {
                path: "handle_manual_action/:id",
                name: "handle_manual_action",
                meta: { label: "Пакетное завершение ручного действия из файла", label_en:"Пакетное завершение ручного действия из файла" },
                component: () => import('@/views/bpm/other/BatchCompletionAction.vue'),
                props: route => ({
                  id:  Number(route.params.id),
                }),
              },
              {
                path: "industries",
                meta: { label: "Справочник отраслей", label_en: "Catalog industries"},
                name: "Industries",
                component: () => import('@/views/bpm/decision_templates/Industries.vue')
              },
              {
                path: "bpm_cases",
                meta: { label: "Кейсы", label_en: "Cases"},
                name: "bpm_cases",
                component: () => import('@/views/bpm/dictionaries/BpmCases.vue')
              },
              {
                  path: "decision_templates",
                  meta: { label: "Шаблоны решений", label_en: "Solution templates" },
                  component: {
                    render(c) {
                      return c("router-view");
                    }
                  },
                  children:[
                    {
                      path: "",
                      name: "decision_templates",
                      meta: { label: "Список", label_en: "List" },
                      component: () => import('@/views/bpm/decision_templates/DecisionTemplates.vue'),
                    },
                    {
                      path: ":id",
                      meta: { label: "Шаблон решения", label_en: "Decision template"},
                      name: "decision_template",
                      component: () => import('@/views/bpm/decision_templates/DecisionTemplate.vue'),
                      props: route => ({
                          id:  Number(route.params.id),
                      }),
                    }
                  ]
              },
              {
                path: 'errors',
                meta: {
                  label: 'Ошибки БП',
                  label_en: 'BP errors',
                },
                name: 'bp-errors',
                props: (route) => ({
                  page:  Number(route.query.page) || 1,
                  filterBy:  route.query.filterBy || '',
                  filterValue:  route.query.filterValue || '',
                }),
                component: () => import('@/views/bpm/errors/BpErrorLogList.vue'),
              },
          ]
      },
      {
        path: "solutions",
        meta: { label: "Решения", label_en: "Solutions"},
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children:[
          {
            path: "",
            name: "solutions",
            meta: { label: "Список решений", label_en: "Solutions list" },
            component: () => import('@/views/bpm/decision_templates/SolutionsForClient.vue'),
            props: route => ({
              tab:  String(route.query.tab),
              edit_last_draft_solution:  Boolean(route.query.edit_last_draft_solution),
            }),
          },
          {
            path: ":id",
            meta: { label: "Решение", label_en: "Solution"},
            name: "solution",
            component: () => import('@/views/bpm/decision_templates/SolutionForClient.vue'),
            props: route => ({
              id:  Number(route.params.id),
            }),
          }
        ],
      },
      {
        path: "campaigns",
        meta: { label: "Кампании", label_en:"Campaigns" },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children:[
          {
            path: "",
            name: "campaigns",
            meta: { label: "Список", label_en: "List" },
            component: () => import('@/views/bpm/campaigns/BotCampaigns.vue'),
            props: route => ({
              page:  Number(route.query.page) || 1,
            }),
          },
          {
            path: ":id",
            meta: { label: "Редактирование", label_en: "Editing"},
            name: "campaign",
            component: () => import('@/views/bpm/campaigns/BotCampaing.vue'),
            props: route => ({
                id:  Number(route.params.id),
            }),
          },
          {
            path: "options/:id",
            meta: { label: "Редактирование настроек", label_en: "Editing options"},
            name: "campaign_options",
            component: () => import('@/views/bpm/campaigns/CampaignOptions.vue'),
            props: route => ({
                id:  Number(route.params.id),
            }),
          },
          {
            path: "options/:id/:method",
            meta: { label: "Создание", label_en: "Create"},
            name: "campaign_options_create",
            component: () => import('@/views/bpm/campaigns/CampaignOptions.vue'),
            props: route => ({
                id:  Number(route.params.id),
                method:  route.params.method,
            }),
          },
        ]
      },
      {
        path: "settings",
        meta: { label: "Настройки", label_en:"Settings" },
        name: "settings",
        component: () => import('@/views/bpm/setup_interfaces/AccountNumbersTabs.vue')
      },
      {
        path: "billing",
        meta: { label: "Биллинг", label_en:"Billing" },
        name: "billing_report",
        component: () => import('@/views/reports/BpmBillingTabsOfReport.vue')
      },
      {
        path: "reports",
        redirect: "/reports/sales",
        meta: { label: "Отчеты", label_en:"Reports" },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "sales",
            meta: { label: "Отчет по продажам", label_en:"Sales reports" },
            name: "sales-report",
            component: SalesReport
          },
          {
            path: "sales_section",
            meta: { label: "Отчет по продажам(Срезы)", label_en:"Sales reports(Slices)" },
            name: "sales-section-report",
            component: SalesSectionReport
          },
          {
            path: "sales_section_v2",
            meta: { label: "Отчет по продажам(Срезы) V2", label_en:"Sales reports(Slices) V2" },
            name: "sales-section-v2-report",
            component: SalesSectionV2Report
          },
          {
            path: "sales_analytics_extend",
            meta: { label: "Отчет аналитики продаж расширенный", label_en:"Sales analytics reports extend" },
            name: "sales-analytics-report-extend",
            component: () => import('@/views/reports/SalesAnalyticsExtendReport.vue')
          },
          {
            path: "common",
            meta: { label: "Общий отчет", label_en: "Common reports" },
            name: "common-report",
            component: CommonReport
          },
          {
            path: "frod",
            meta: { label: "Отчет по фроду", label_en: "Verification reports" },
            name: "frod-report",
            component: FrodReport
          },
          {
            path: "wholesale_sales",
            meta: { label: "Отчет по отделу оптовых продаж", label_en: "Verification reports" },
            name: "wholesale_sales-report",
            component: WholesaleSalesReport
          },
          {
            path: "accounting",
            meta: { label: "Отчет по бухгатлерии", label_en: "Accounting reports" },
            name: "accounting-report",
            component: AccountingReport
          },
          {
            path: "logistic",
            meta: { label: "Отчет по логистике", label_en: "Logistics Reports" },
            name: "logistic-report",
            component: LogisticReport
          },
          {
            path: "call_center_wages_extended",
            meta: { label: "Отчет по ЗП Коллцентра расширенный", label_en: "Call-center wages reports extend" },
            name: "call-center-wages-extended",
            component: CallCenterWagesExtendedReport
          },
          {
            path: "call_center_wages_extended_v2",
            meta: { label: "Отчет по ЗП Х/П для Колл-центра V2", label_en: "Call-center wages reports extend V2" },
            name: "call-center-wages-extended-v2",
            component: CallCenterWagesExtendedReportV2
          },
          {
            path: "call_center_wages_extended_v2_setup",
            meta: { label: "Настройки для ЗП Х/П для Колл-центра V2", label_en: "Setup for call-center wages reports extend V2" },
            name: "call-center-wages-extended-v2-setup",
            component: CallCenterWagesExtendedReportV2Setup
          },
          {
            path: "fines_report",
            meta: { label: "Отчет по штрафам", label_en: "Fines report" },
            name: "fines-report",
            component: FinesReport
          },
          {
            path: "sms_link_short_report",
            meta: { label: "Отчет по СМС и переходам", label_en: "Report on SMS and transitions" },
            name: "sms_link_short_report",
            component: SmsLinkShortReport
          },
          {
            path: "bpm_auto_report",
            meta: { label: "Автоматизированный отчет БП", label_en: "Automated BP report" },
            name: "bpm_auto_report",
            component: BpmAutoReport
          },
          {
            path: "abnormal_traffic",
            meta: { label: "Отчет. Аномальный трафик.", label_en: "Report. Abnormal traffic" },
            name: "abnormal-traffic-report",
            component: AbnormalTrafficReport
          },
          {
            path: "average_transition",
            meta: { label: "Среднее время перехода по ссылкам", label_en: "Average time to click on links" },
            name: "average-transition-report",
            component: AverageTransitionTime
          },
          {
            path: "report_of_manual_and_automatic_ringing",
            meta: { label: "Отчет по ручному и автоматическому прозвону", label_en: "Report of manual and automatic ringing" },
            name: "report-of-manual-and-automatic-ringing",
            component: ReportOfManualAndAutomaticRinging
          },
          {
            path: "call_center_wages_time_on_line",
            meta: { label: "Отчет времени на линии", label_en: "Call-center wages reports time on line" },
            name: "call-center-wages-time-on-line",
            component: CallCenterWagesTimeOnLineReport
          },
          {
            path: "survey",
            meta: { label: "Отчет по опросу", label_en:"Survey report" },
            name: "survey-report",
            component: SurveyReport
          },
          {
            path: "calls_monitoring",
            meta: { label: "Отчёты. Мониторинг звонков", label_en: "Calls monitoring report" },
            name: "calls-monitoring",
            component: CallsMonitoringReport
          },
          {
              path: "consolidated_report/:setting?",
              meta: {
                  label: "Сводный отчёт",
                  label_en: "Consolidated report",
              },
              name: "consolidated-report",
              props: route => ({
                  setting_name: String(route.params.setting),
              }),
              component: () => import('@/views/reports/ConsolidatedReport.vue'),
          },
          {
            path: "delivery-dpd-report",
            meta: { label: "Отчет по DPD", label_en: "DPD report"},
            name: "DeliveryDpdReport",
            component: DeliveryDpdReport
          },
          {
            path: "logistic-calls-report",
            meta: { label: "Отчет прозвона по логистам", label_en: "Logistic calls report" },
            name: "logistic-calls-report",
            component: LogisticCallsReport
          },
          {
            path: "med-project-report",
            meta: { label: "Отчет по мед. проекту", label_en: "Med. project report" },
            name: "med-project-report",
            component: MedProjectReport
          },
          {
              path: "calls_state",
              meta: { label: "Звонки статистика", label_en: "Calls statistics" },
              name: "calls_state",
              component: CallsState
          },
          {
              path: "robot_orders_report",
              meta: { label: "Отчет по роботу", label_en: "Robot report" },
              name: "robot_orders_report",
              component: RobotOrdersReport
          },
          {
              path: "robot_call_period_report",
              meta: { label: "Отчет по звонкам робота"},
              name: "robot_call_period_report",
              component: () => import('@/views/reports/RobotCallPeriodReport.vue'),
          },
          {
            path: "test_questions_report",
            meta: { label: "Отчет по вопросам теста" },
            name: "test_questions_report",
            component: () => import('@/views/reports/TestQuestionsReport.vue'),
          },
          {
            path: "test_grades_report",
            meta: { label: "Отчёт по оценкам теста" },
            name: "test_grades_report",
            component: () => import('@/views/reports/TestGradesReport.vue'),
          },
          {
            path: "vvch_recritong_report",
            meta: { label: "Отчет. ВВЧ по рекрутингу" },
            name: "vvch_recritong_report",
            component: () => import('@/views/reports/VvchRecrutingReport.vue')
          },
          {
            path: "report_on_bases",
            meta: { label: "Отчет по базам" },
            name: "report_on_bases",
            component: () => import('@/views/reports/ReportOnBases.vue')
          },
          {
            path: "report_bpm",
            meta: { label: "Отчет по БП", label_en:"Business process report"},
            name: "report_bpm",
            component: () => import('@/views/reports/ReportBpm.vue')
          },
        ]
      },
      {
        path: "check",
        redirect: "/check/check_robot_in_orders",
        meta: { label: "Анализ и сверка", label_en: "Analysis and check" },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "check_robot_in_orders",
            meta: { label: "Проверка по диалогам роботов", label_en: "Checking robots dialogues" },
            name: "check_robot_in_orders",
            component: () => import('@/views/analysis_and_check/CheckRobotInOrders.vue')
          },
        ]
      },
      {
        path: "ats",
        redirect: "/ats/servers",
        meta: { label: "АТС", label_en: "ATE" },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "providers",
            meta: { label: "Провайдеры связи", label_en: "Providers" },
            name: "providers",
            component: Providers
          },
          {
            path: "out_routes",
            meta: { label: "Исходящие маршруты", label_en: "Outgoing" },
            name: "out_routes",
            component: OutRoutes
          },
          {
            path: "sips",
            meta: { label: "Транки", label_en: "Trunks" },
            name: "ats-sips-list",
            component: AtsSips
          },
          {
            path: "servers",
            meta: { label: "Сервера", label_en: "Servers" },
            name: "ats-servers-list",
            component: AtsServers
          },
          {
            path: "groups",
            meta: { label: "Группы АТС", label_en: "ATS groups" },
            name: "ats-groups-list",
            component: AtsGroups
          },
          {
            path: "users",
            meta: { label: "Пользователи АТС", label_en: "ATS users" },
            name: "ats-users-list",
            component: AtsUsers
          },
          {
            path: "queues",
            meta: { label: "Очереди АТС", label_en: "Queues" },
            name: "ats-queues",
            component: AtsQueues
          },
          {
            path: "queues_groups",
            meta: { label: "Группы очередей АТС", label_en: "Queue Groups" },
            name: "ats-queue-groups",
            component: AtsQueueGroups
          },
          {
            path: "monitoring",
            meta: { label: "Мониторинг", label_en: "Monitoring" },
            name: "ats-monitoring",
            component: AtsMonitoring
          },
          {
              path: "tel_check",
              meta: { label: "АТС. Аномалии", label_en: "ATS. Anomalies" },
              name: "tel_check",
              component: CallsCheck
          },
          {
            path: "chat_bot_services",
            meta: { label: "Сервисы чат ботов", label_en:"Chat bot services" },
            name: "chat-bot-services",
            component: () => import('@/views/services/ChatBotSetups.vue'),
            children: [
              {
                path: ":id",
                meta: { label: "Настройка", label_en:"Setups" },
                name: "chat-bot-service",
                props: (route) => ({
                  id: Number(route.params.id),
                }),
                component: () => import('@/views/services/editor_chat_bot_setup/ChatBotSetup.vue'),
              },
            ],
          },
          {
            path: "chat_bot_setup_groups",
            meta: { label: "Группы сервисов чат ботов", label_en:"Chat bot groups" },
            name: "chat-bot-setup-groups",
            component: () => import('@/views/services/ChatBotSetupGroups.vue')
          },
        ]
      },
      {
        path: "profile",
        meta: { label: "Профиль", label_en: "Profile" },
        name: "Profile",
        component: () => import("@/views/profile/ProfilePage.vue"),
      },
      {
        path: "incoming_call_alert",
        meta: { label: "Оповещение о входящем звонке", label_en: "Incoming call alert" },
        name: "IncomingCallAlert",
        component: IncomingCallAlert
      },
      {
        path: "wholesaler_orders",
        meta: { label: "Оптовичок" },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "new_opt_order",
            meta: { label: "Новая заявка" },
            component: NewOptOrder
          },
          {
            path: "",
            name: "wholesaler_orders",
            meta: { label: "Список заявок" },
            component: WholesalerOrders
          },
          {
            path: ":key",
            meta: { label: "Редактирование заявки" },
            name: "wholesaler_order-edit",
            component: WholesalerOrder
          }
        ]
      },
      {
        path: "wholesaler_services",
        meta: { label: "Оптовик. Услуги" },
        name: "WholesalerServices",
        component: WholesalerServices
      },
      {
        path: "wholesaler_stocks",
        meta: { label: "Оптовик. Склады" },
        name: "WholesalerStocks",
        component: WholesalerStocks
      },
      {
        path: "wholesaler_purchases",
        meta: { label: "Оптовик. Закупки" },
        name: "WholesalerPurchases",
        component: WholesalerPurchases
      },
      {
        path: "wholesaler_acceptance",
        meta: { label: "Оптовик. Приёмка" },
        name: "WholesalerAcceptance",
        component: WholesalerAcceptance
      },
      {
        path: "wholesaler_clients",
        meta: { label: "Оптовик. Клиенты" },
        name: "WholesalerClients",
        component: WholesalerClients
      },
      {
        path: "wholesaler_sale_statuses",
        meta: { label: "Оптовик. Статусы продаж" },
        name: "WholesalerSaleStatuses",
        component: SaleStatuses
      },
      {
        path: "wholesaler_unloads",
        meta: { label: "Оптовик. Выгрузки" },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "",
            meta: { label: "Список выгрузок" },
            name: "WholesalerUnloads",
            component: WholesalerUnloads
          },
          {
            path: "wholesaler_unload/:id?",
            meta: { label: "Редактор выгрузки" },
            name: "WholesalerUnload",
            component: WholesalerUnload
          }
        ]
      },
      {
        path: "opt_traffic_sources",
        meta: { label: "Оптовичок. Источники трафика"},
        name: "opt_traffic_sources",
        component: OptTrafficSources
      },
      {
          path: "404",
          meta: { label: "Не найдено", label_en: "Not found"},
          name: "NotFound",
          component: () => import('@/views/app/NotFound.vue')
      },
      {
        path: "webinars",
        meta: { label: "Вебинары" },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "",
            meta: { label: "Вебинары" },
            name: "Webinars",
            component: Webinars
          },
          {
            path: "webinar_course",
            meta: { label: "Курсы" },
            name: "WebinarCourse",
            component: WebinarCourse
          },
          {
            path: "webinar_course_item",
            meta: { label: "Записи вебинаров" },
            name: "WebinarCourseItem",
            component: WebinarCourseItem
          }
        ]
      },

      {
        path: "others/owners",
        meta: { label: "Владельцы", label_en: "Owners" },
        name: "Owners",
        component: Owners
      },
      {
        path: "hr/competence_cats",
        meta: { label: "Категории компетенций" },
        name: "CompetenceCats",
        component: () => import('@/views/competence_cats/CompetenceCats.vue')
      },
      {
        path: "hr/test_questions",
        meta: { label: "Тестовые вопросы" },
        name: "TestQuestions",
        component: () => import('@/views/test_questions/TestQuestions.vue')
      },
      {
        path: "hr/question_groups",
        meta: { label: "Группы вопросов" },
        name: "QuestionGroups",
        component: () => import('@/views/test_questions/QuestionGroups.vue')
      },
      {
        path: "hr/tests",
        meta: { label: "Тесты" },
        name: "Tests",
        component: () => import('@/views/tests/Tests.vue')
      },
      {
        path: "hr/competences",
        meta: {label: "Компетенции"},
        name: "Competences",
        component: () => import('@/views/competences/Competences.vue')
      },
      {
        path: "hr/competence_groups",
        meta: {label: "Группы компетенций"},
        name: "CompetenceGroups",
        component: () => import('@/views/competences/CompetenceGroups.vue'),
      },
      {
        path: "hr/points_with_grades",
        meta: {label: "Связь баллов с оценкой"},
        name: "PointsWithGrades",
        component: () => import('@/views/interpretations/PointsWithGrades.vue'),
      },
      {
        path: "hr/user_tests",
        meta: { label: "Сгенерированные тесты" },
        name: "user_tests",
        component: () => import('@/views/tests/UserTests.vue'),
      },
      {
        path: "hr/grade_options",
        meta: {label: "Настройки подсчета оценки"},
        name: "GradeOptions",
        component: () => import('@/views/interpretations/GradeOptions.vue'),
      },
      {
        path: "hr/interpretations",
        meta: {label: "Интерпретации"},
        name: "Interpretation",
        component: () => import("@/views/interpretations/Interpretations.vue")
      },
      {
        path: "hr/user_forms",
        meta: {label: "Анкеты"},
        component: {
            render(c) {
                return c("router-view");
            }
        },
        children: [
            {
                path: "",
                meta: { label: "Анкеты", label_en:"User forms" },
                name: "user-forms",
                component: () => import('@/views/hr/UserForms.vue'),
            },
            {
                path: "expired",
                meta: { label: "Просроченные анкеты", label_en:"Expired forms" },
                name: "expired-user-forms",
                component: () => import('@/views/hr/ExpiredUserForms.vue'),
            },
            {
                path: ":id",
                meta: { label: "Анкета", label_en:"User form" },
                name: "user-form",
                props: route => ({
                    id: Number(route.params.id),
                    order_key: String(route.query.order_key),
                }),
                component: () => import('@/views/hr/UserForm.vue'),
            }
        ]
      },
      {
          path: "hr/simple_user_form",
          meta: { label: "Анкета", label_en:"User form" },
          name: "SimpleUserForm",
          component: () => import('@/views/hr/SimpleUserForm.vue'),
      },
      {
          path: "ats/add_setup_sip_caller_id",
          meta: { label: "Доп.настройки для сипа", label_en:"Additional setup sip" },
          name: "AddSetupCallerId",
          component: () => import('@/views/ats/AddSetupCallerId.vue'),
      },
      {
        path: "hr/vacancies",
        meta: {label: "Вакансии"},
        name: "vacancies",
        component: () => import('@/views/hr/Vacancies.vue')
      },
      {
        path: "hr/skills",
        meta: {label: "Навыки"},
        name: "skills",
        component: () => import('@/views/hr/Skills.vue')
      },
      {
        path: "hr/companies",
        meta: {label: "Компании"},
        name: "companies",
        component: () => import('@/views/hr/Companies.vue')
      },
      {
        path: "hr/divisions",
        meta: {label: "Отделы"},
        name: "divisions",
        component: () => import('@/views/hr/Divisions.vue')
      },
      {
        path: "hr/chiefs",
        meta: {label: "Руководители"},
        name: "chiefs",
        component: () => import('@/views/hr/Chiefs.vue')
      },
      {
        path: "hr/user_form_analytics",
        meta: {label: "Аналитика"},
        name: "user_form_analytics",
        component: () => import('@/views/hr/UserFormAnalytics.vue')
      },
      {
        path: "hr/rates",
        meta: {label: "Оценки"},
        name: "rates",
        component: () => import('@/views/hr/Rates.vue')
      },
      {
        path: "packed",
        meta: { label: "Упаковщик", label_en: "Packed"},
        name: "Packed",
        component: Packed
      },
      {
        path: "unpacked",
        meta: { label: "Возвраты", label_en: "Returns"},
        name: "Unpacked",
        component: Unpacked
      },
      {
        path: "packed-barcodes",
        meta: { label: "Отсканированные штрих-коды", label_en: "Scanned barcodes"},
        name: "PackedBarcode",
        component: PackedBarcode
      },
      {
        path: "who-packed",
        meta: { label: "Кто отсканировал", label_en: "Who packed"},
        name: "WhoPacked",
        component: WhoPacked
      },
      {
        path: "packed-standards",
        meta: { label: "Нормы скорости", label_en: "Speed standards"},
        name: "PackedSpeedStandards",
        component: PackedSpeedStandards
      },
      {
        path: "packed-report",
        meta: { label: "Отчет по складу", label_en: "Warehouse report"},
        name: "PackedReport",
        component: PackedReport
      },
      {
        path: "robot/scenarios",
        meta: { label: "Справочник сценариев", label_en: "Scenarios"},
        name: "RobotScenarios",
        component: RobotScenarios
      },
      {
        path: "report-builder",
        redirect: { name: 'RBReports' },
        meta: { label: "Конструктор отчетов", label_en: "Report builder"},
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "sources",
            meta: { label: "Источники данных", label_en: "Data sources"},
            name: "RBSources",
            component: () => import('@/views/report_builder/constructor/sources/RBSources.vue'),
          },
          {
            path: "reports",
            meta: { label: "Отчеты", label_en: "Reports"},
            name: "RBReports",
            component: () => import('@/views/report_builder/constructor/reports/RBReports.vue'),
          },
          {
            path: "templates",
            meta: { label: "Шаблоны", label_en: "Templates"},
            component: {
              render(c) {
                return c("router-view");
              }
            },
            children: [
              {
                path: "",
                meta: { label: "Список шаблонов", label_en: "Templates"},
                name: "ReportBuilderTemplates",
                component: () => import('@/views/report_builder/constructor/templates/RBTemplates.vue'),
              },
              {
                path: "create",
                meta: { label: "Создать", label_en: "Create"},
                name: "ReportBuilderCreateTemplate",
                component: () => import('@/views/report_builder/constructor/templates/RBTemplateCreateOrEdit.vue'),
              },
              {
                path: "edit/:id",
                meta: { label: "Редактировать", label_en: "Edit"},
                name: "ReportBuilderEditTemplate",
                component: () => import('@/views/report_builder/constructor/templates/RBTemplateCreateOrEdit.vue'),
                props: route => ({
                  templateId: Number(route.params.id),
                }),
              },
            ]
          },
          {
            path: "dashboards",
            meta: { label: "Дашборды", label_en: "Dashboards"},
            name: "RBDashboards",
            component: () => import('@/views/report_builder/constructor/dashboards/RBDashboards.vue'),
          },
        ]
      },
      {
        path: "greports",
        meta: { label: "Отчеты", label_en: "Reports"},
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "",
            meta: { label: "Список отчетов", label_en: "Reports"},
            name: "RBReportList",
            component: () => import('@/views/report_builder/reports/RBReportList.vue'),
          },
          {
            path: ":id",
            meta: { label: "Отчет", label_en: "Report"},
            name: "RBReport",
            component: () => import('@/views/report_builder/reports/RBReport.vue'),
            props: route => ({
              reportId: Number(route.params.id),
            }),
          }
        ]
      },
      {
        path: "dashboards/:id",
        meta: { label: "Дашборд", label_en: "Dashboard"},
        name: "RBDashboard",
        component: () => import('@/views/report_builder/dashboards/RBDashboard.vue'),
        props: route => ({
          dashboardId: Number(route.params.id),
        }),
      },
      {
        path: "link-shortener/domains",
        meta: { label: "Домены", label_en: "Domains"},
        name: "DomainList",
        component: () => import('@/views/link_shortener/domains/DomainList.vue'),
      },
      {
        path: "link-shortener",
        meta: { label: "Укоротитель ссылок", label_en: "Link shortener"},
        name: "DomainKeys",
        component: () => import('@/views/link_shortener/domain_keys/DomainKeys.vue'),
      },
      {
        path: 'ml',
        meta: { label: 'Машинное обучение', label_en: 'Machine learning' },
        name: 'ml',
        component: () => import('@/views/ml/MlDashboard.vue'),
        children: [
          {
            path: 'nlu-servers',
            meta: { label: 'Сервера NLU', label_en: 'NLU servers' },
            name: 'ml-nlu-servers',
            component: () => import('@/views/ml/nlu/NluServerList.vue'),
            children: [
              {
                path: 'history',
                meta: { label: 'История', label_en: 'History' },
                name: 'ml-nlu-servers-history',
                component: () => import('@/views/ml/nlu/NluServerHistory.vue'),
                props: (route) => ({
                  page:  Number(route.query.page) || 1,
                }),
              },
            ],
          },
          {
            path: 'nlu-groups',
            meta: { label: 'Группы NLU', label_en: 'NLU groups' },
            name: 'ml-nlu-groups',
            component: () => import('@/views/ml/nlu/NluGroupList.vue'),
            children: [
              {
                path: 'history',
                meta: { label: 'История', label_en: 'History' },
                name: 'ml-nlu-groups-history',
                component: () => import('@/views/ml/nlu/NluGroupHistory.vue'),
                props: (route) => ({
                  page:  Number(route.query.page) || 1,
                }),
              },
            ],
          },
          {
            path: 'nlu',
            meta: { label: 'NLU', label_en: 'NLU' },
            name: 'ml-nlu',
            component: () => import('@/views/ml/nlu/NluList.vue'),
            props: route => ({
              page: Number(route.query.page) || 1,
            }),
            children: [
              {
                path: ':name',
                meta: { label: 'Просмотр', label_en: 'View' },
                name: 'ml-nlu-view',
                component: () => import('@/views/ml/nlu/NluItem.vue'),
                props: true,
              },
            ],
          },
        ],
      },
    ]
  },
  {
      path: '*',
      redirect: '/404'
  },

  // { path: '/orders', component: Orders, name: 'order-list'},
  // { path: '/orders/:key', component: Order, name: 'order-edit'},

  // { path: '/products/', component: Products, name: 'product-list' },
  // { path: '/products/:id', component: Product, name: 'product-edit' },

  // { path: '/statuses/', component: Statuses, name: 'status-list' },
  // { path: '/status_tree/', component: StatusTree, name: 'status-tree' },

  // { path: '/delivery_types/', component: DeliveryTypes, name: 'delivery-type-list'},
  // { path: '/delivery_types/:id', component: DeliveryType, name: 'delivery-type-edit'},

  // { path: '/projects/', component: Projects, name: 'project-list'},
  // { path: '/projects/:id', component: Project, name: 'project-edit'},

  // { path: '/sms_providers/', component: SmsProviders, name: 'sms-providers-list'},
  // { path: '/sms_providers/:id', component: SmsProvider, name: 'sms-provider-edit'},

  // { path: '/sms_send/', component: SmsSend, name: 'sms-send'},

  // { path: '/unloads/', component: Unloads, name: 'unload-list' },
  // { path: '/unloads/:id', component: Unload, name: 'unload-edit' },

  // { path: '/organizations/:id?', component: Organizations, name: 'organization-list'},

  // { path: '/geos/', component: Geos, name: 'geo-list'},
  // { path: '/geos/:id', component: Geo, name: 'geo-edit'},

  // { path: '/calls/', component: Calls, name: 'calls-list'},

  // { path: '/project_category/', component: ProjectCategories, name: 'project-category-list'},
  // { path: '/project_category/:id', component: ProjectCategory, name: 'project-category-edit'},

  // { path: '/traffics/', component: Traffics, name: 'traffic-list'},
  // { path: '/traffics/:id', component: Traffic, name: 'traffic-edit'},

  // { path: '/product_category/', component: ProductCategories, name: 'product-category-list' },
  // { path: '/product_category/:id', component: ProductCategory, name: 'product-category-edit' },

  // { path: '/call_center/', component: CallCenters, name: 'call-center-list'},
  // { path: '/call_center/:id', component: CallCenter, name: 'call-center'},

  // { path: '/currency/', component: Currencies, name: 'currency-list'},
  // { path: '/currency/:id', component: Currency, name: 'currency'},

  // { path: '/entities/', component: Entities, name: 'entity-list'},
  // { path: '/entities/:id', component: Entity, name: 'entity-edit'},

  // { path: '/role_groups/', component: RoleGroups, name: 'role-group-list'},
  // { path: '/role_groups/:id', component: RoleGroup, name: 'role-group-edit'},
];

export default new VueRouter({
  mode: "history",
  routes,
  parseQuery: q => {
    return qs.parse(q);
  },
  stringifyQuery: q => {
    let result = qs.stringify(q);

    return result ? "?" + result : "";
  },
  linkActiveClass: "active"
});
