import http from "./../../lib/http";
import api from "./../../lib/api";

export const ORGANIZATION_CURRENT_LOAD = "ORGANIZATION_CURRENT_LOAD";
const ORGANIZATION_CURRENT_CHANGE = "ORGANIZATION_CURRENT_CHANGE";
const ORGANIZATION_CURRENT_LOADING_CHANGE =
  "ORGANIZATION_CURRENT_LOADING_CHANGE";

export const ORGANIZATION_LOAD = "ORGANIZATION_LOAD";
export const ORGANIZATION_UPDATE = "ORGANIZATION_UPDATE";
export const ORGANIZATION_CREATE = "ORGANIZATION_CREATE";
export const ORGANIZATION_CREATE_INTERFACE = "ORGANIZATION_CREATE_INTERFACE";

export const ORGANIZATION_LOAD_DATATABLE = "ORGANIZATION_LOAD_DATATABLE";

export const ORGANIZATION_GET_API_KEY = "ORGANIZATION_GET_API_KEY";
export const ORGANIZATION_SET_API_KEY_BPM_INTERFACE = "ORGANIZATION_SET_API_KEY_BPM_INTERFACE";

const ORGANIZATION_API_KEY_CHANGE = "ORGANIZATION_API_KEY_CHANGE";
const ORGANIZATION_API_KEY_LOADING_CHANGE =
  "ORGANIZATION_API_KEY_LOADING_CHANGE";

export const ORGANIZATION_CHANGE = "ORGANIZATION_CHANGE";
export const ORGANIZATION_LOAD_DATATABLE_BPM_BP = "ORGANIZATION_LOAD_DATATABLE_BPM_BP";
const ORGANIZATION_LOADING_CHANGE = "ORGANIZATION_LOADING_CHANGE";
const ORGANIZATION_SAVING_CHANGE = "ORGANIZATION_SAVING_CHANGE";
const ORGANIZATION_CREATE_CHANGE = "ORGANIZATION_CREATE_CHANGE";
export const ORGANIZATION_ERRORS_CHANGE = "ORGANIZATION_ERRORS_CHANGE";

const ORGANIZATION_CREATING_CHANGE = "ORGANIZATION_CREATING_CHANGE";

const ORGANIZATION_DATATABLE_CHANGE = "ORGANIZATION_DATATABLE_CHANGE";
const ORGANIZATION_DATATABLE_LOADING_CHANGE =
  "ORGANIZATION_DATATABLE_LOADING_CHANGE";

export const ORGANIZATION_LOAD_COMPANY = "ORGANIZATION_LOAD_COMPANY";
const ORGANIZATION_COMPANY_CHANGE = "ORGANIZATION_COMPANY_CHANGE";

export const ORGANIZATION_LOAD_BY_ROLE = "ORGANIZATION_LOAD_BY_ROLE";
const ORGANIZATION_LOAD_BY_ROLE_CHANGE = "ORGANIZATION_LOAD_BY_ROLE_CHANGE";

export const ORGANIZATION_LIST_LOAD = "ORGANIZATION_LIST_LOAD";
const ORGANIZATION_LIST_LOAD_CHANGE = "ORGANIZATION_LIST_LOAD_CHANGE";

export const ORGANIZATION_LOAD_FOR_TREE = "ORGANIZATION_LOAD_FOR_TREE";
const ORGANIZATION_LOAD_FOR_TREE_CHANGE = "ORGANIZATION_LOAD_FOR_TREE_CHANGE";

export const ORGANIZATION_ATTACH_ROLE = "ORGANIZATION_ATTACH_ROLE";
export const ORGANIZATION_DETACH_ROLE = "ORGANIZATION_DETACH_ROLE";
const ORGANIZATION_ATTACH_CHANGE = "ORGANIZATION_ATTACH_CHANGE";

export const ORGANIZATION_LOAD_BY_ORGANIZATION =
  "ORGANIZATION_LOAD_BY_ORGANIZATION";
const ORGANIZATION_LOAD_BY_ORGANIZATION_CHANGE =
  "ORGANIZATION_LOAD_BY_ORGANIZATION_CHANGE";
export const ORGANIZATION_LOAD_FOR_SMS_TEMPLATE = "ORGANIZATION_LOAD_FOR_SMS_TEMPLATE";
const ORGANIZATION_LOAD_FOR_SMS_TEMPLATE_CHANGE = "ORGANIZATION_LOAD_FOR_SMS_TEMPLATE_CHANGE";

export const ORGANIZATION_BALANCE_LOAD_DATATABLE = "ORGANIZATION_BALANCE_LOAD_DATATABLE";
export const ORGANIZATION_BALANCE_EMPTY = "ORGANIZATION_BALANCE_EMPTY";
const ORGANIZATION_BALANCE_DATATABLE_LOADING_CHANGE = "ORGANIZATION_BALANCE_DATATABLE_LOADING_CHANGE";
const ORGANIZATION_BALANCE_DATATABLE_CHANGE = "ORGANIZATION_BALANCE_DATATABLE_CHANGE";
const ORGANIZATION_BALANCE_SET_EMPTY = "ORGANIZATION_BALANCE_SET_EMPTY";

const ORGANIZATION_SAVING_INTERFACE_CHANGE = "ORGANIZATION_SAVING_INTERFACE_CHANGE";
const ORGANIZATION_CREATE_INTERFACE_CHANGE = "ORGANIZATION_CREATE_INTERFACE_CHANGE";
const ORGANIZATION_ERRORS_INTERFACE_CHANGE = "ORGANIZATION_ERRORS_INTERFACE_CHANGE";

export const ORGANIZATION_BALANCE_CREATE = "ORGANIZATION_BALANCE_CREATE";
const ORGANIZATION_BALANCE_CREATE_LOADING = "ORGANIZATION_BALANCE_CREATE_LOADING";
const ORGANIZATION_BALANCE_CREATE_LOADED = "ORGANIZATION_BALANCE_CREATE_LOADED";
const ERRORS_ORGANIZATION_BALANCE = "ERRORS_ORGANIZATION_BALANCE";

const state = {
  organization: null,
  organization_loading: false,
  organization_saving: false,
  organization_errors: {},

  organization_current: null,
  organization_current_loading: null,

  organization_api_key: null,
  organization_api_key_loading: null,

  organization_list: null,
  organization_list_loading: false,

  organization_creating: false,
  organization_creating_errors: {},

  organization_company_list: null,

  organization_list_by_role: [],
  organization_list_by_organization: [],
  organizations: [],

  organizations_for_tree: [],

  organization_attach_result: null,

  organization_interface_saving: false,
  organization_interface: null,
  organization_interface_errors: {},

  balance_list_loading   : false,
  balance_list_total     : 0, 
  balance_list           : null,
  balance_loading        : null,
  balance_errors         : {}
};

const actions = {
  [ORGANIZATION_LOAD]({ commit, state }, id) {
    if (state.organization_list) {
      let ret = false;
      state.organization_list.forEach(org => {
        if (org.id == id) {
          commit(ORGANIZATION_CHANGE, { ...org });
          ret = true;
        }
      });

      if (ret) {
        return;
      }
    }
    return api.load(
      "organization",
      id,
      state,
      commit,
      ORGANIZATION_LOADING_CHANGE,
      ORGANIZATION_CHANGE
    );
  },

  [ORGANIZATION_CURRENT_LOAD]({ commit, state }, id) {
    if (!id) {
      commit(ORGANIZATION_CURRENT_CHANGE, null);

      return;
    }

    let request = api.load(
      "organization",
      id,
      state,
      commit,
      ORGANIZATION_CURRENT_LOADING_CHANGE,
      ORGANIZATION_CURRENT_CHANGE
    );

    return request;
  },

  [ORGANIZATION_UPDATE]({ commit, state }, data) {
    let id = data.id;
    let model = data.model;

    let request = api.update(
      "organization",
      id,
      model,
      state,
      commit,
      ORGANIZATION_SAVING_CHANGE,
      ORGANIZATION_CHANGE,
      ORGANIZATION_ERRORS_CHANGE
    );

    return request;
  },

  [ORGANIZATION_CREATE]({ commit, state }, data) {
    let model = data.model;
    let id = data.id ? data.id : 0;

    let request = api.create(
      "organization.children",
      id,
      model,
      state,
      commit,
      ORGANIZATION_SAVING_CHANGE,
      ORGANIZATION_CREATE_CHANGE,
      ORGANIZATION_ERRORS_CHANGE
    );

    return request;
  },

  [ORGANIZATION_CREATE_INTERFACE]({ commit, state }, data) {
    return api.create(
      "organization.interface.create",
      0,
      data,
      state,
      commit,
      ORGANIZATION_SAVING_INTERFACE_CHANGE,
      ORGANIZATION_CREATE_INTERFACE_CHANGE,
      ORGANIZATION_ERRORS_INTERFACE_CHANGE
    );
  },

  [ORGANIZATION_LOAD_DATATABLE]({ commit, state }, id) {
    if (!id) {
      id = 0;
    }

    return api.list(
      "organization.children",
      id,
      null,
      state,
      commit,
      ORGANIZATION_DATATABLE_LOADING_CHANGE,
      ORGANIZATION_DATATABLE_CHANGE
    );
  },

  [ORGANIZATION_LOAD_DATATABLE_BPM_BP]({ commit, state }, id) {
    return api.list(
      "organization.bpm_bp",
      null,
      null,
      state,
      commit,
      ORGANIZATION_DATATABLE_LOADING_CHANGE,
      ORGANIZATION_DATATABLE_CHANGE
    );
  },

  [ORGANIZATION_LOAD_COMPANY]({ commit, state }, id) {
    if (!id) {
      id = 0;
    }

    return api.list(
      "organization.company",
      id,
      null,
      state,
      commit,
      ORGANIZATION_DATATABLE_LOADING_CHANGE,
      ORGANIZATION_COMPANY_CHANGE
    );
  },

  [ORGANIZATION_LOAD_BY_ORGANIZATION]({ commit, state }, id) {
    if (!id) {
      id = 0;
    }

    return api.list(
      "organization.by_organization",
      id,
      null,
      state,
      commit,
      ORGANIZATION_DATATABLE_LOADING_CHANGE,
      ORGANIZATION_LOAD_BY_ORGANIZATION_CHANGE
    );
  },

  [ORGANIZATION_LOAD_FOR_SMS_TEMPLATE]({ commit, state }, id) {
    if (!id) {
      id = 0;
    }
    let filter = {
        filter:
            '[[["is_company","=","true"]]]'
    };
    return api.list(
        "organization.for_sms_template",
        id,
        null,//filter,
        state,
        commit,
        ORGANIZATION_DATATABLE_LOADING_CHANGE,
        ORGANIZATION_LOAD_FOR_SMS_TEMPLATE_CHANGE
    );
  },

  [ORGANIZATION_LOAD_BY_ROLE]({ commit, state }, id) {
    if (!id) {
      id = 0;
    }

    return api.list(
      "organization.by_role", 
      id,
      null,
      state,
      commit,
      ORGANIZATION_DATATABLE_LOADING_CHANGE,
      ORGANIZATION_LOAD_BY_ROLE_CHANGE
    );
  },

  [ORGANIZATION_LIST_LOAD]({ commit, state }, id) {
    if (!id) {
      id = 0;
    }

    return api.list(
      "organization.list",
      id,
      null,
      state,
      commit,
      ORGANIZATION_DATATABLE_LOADING_CHANGE,
      ORGANIZATION_LIST_LOAD_CHANGE
    );
  },

  [ORGANIZATION_LOAD_FOR_TREE]({ commit, state }, data) {
    let model = data.model;
    let id = data.id ? data.id : 0;

    return api.create(
      "organization.for_tree",
      id,
      model,
      state,
      commit,
      ORGANIZATION_DATATABLE_LOADING_CHANGE,
      ORGANIZATION_LOAD_FOR_TREE_CHANGE,
      ORGANIZATION_ERRORS_CHANGE
    );
  },

  [ORGANIZATION_ATTACH_ROLE]({ commit, state }, data) {
    let model = data.model;
    let id = data.id ? data.id : 0;

    let request = api.create(
      "organization.attach_role",
      data.id,
      data.model,
      state,
      commit,
      ORGANIZATION_SAVING_CHANGE,
      ORGANIZATION_ATTACH_CHANGE,
      ORGANIZATION_ERRORS_CHANGE
    );

    return request;
  },

  [ORGANIZATION_DETACH_ROLE]({ commit, state }, data) {
    let model = data.model;
    let id = data.id ? data.id : 0;

    let request = api.create(
      "organization.detach_role",
      id,
      model,
      state,
      commit,
      ORGANIZATION_SAVING_CHANGE,
      ORGANIZATION_ATTACH_CHANGE,
      ORGANIZATION_ERRORS_CHANGE
    );

    return request;
  },

  [ORGANIZATION_GET_API_KEY]({ commit, state }, id) {   

    let request = api.load(
      "organization.get_api_key",
      0,
      state,
      commit,
      ORGANIZATION_API_KEY_LOADING_CHANGE,
      ORGANIZATION_API_KEY_CHANGE
    );

    return request;
  },

  [ORGANIZATION_SET_API_KEY_BPM_INTERFACE]({ commit, state }, id) {   

    return api.load(
      "organization.set_api_key_bpm_interface",
      0,
      state,
      commit,
      ORGANIZATION_API_KEY_LOADING_CHANGE,
      ORGANIZATION_API_KEY_CHANGE,
      ORGANIZATION_ERRORS_CHANGE
    );
  },

  [ORGANIZATION_BALANCE_LOAD_DATATABLE] ({ commit, state }, data) {
      return api.list(
          'balance.organization', 
          data.id,
          data.data,
          state,
          commit,
          ORGANIZATION_BALANCE_DATATABLE_LOADING_CHANGE, 
          ORGANIZATION_BALANCE_DATATABLE_CHANGE
      );
  },
  [ORGANIZATION_BALANCE_CREATE] ({ commit, state }, data) {
      return api.create(
          'balance.organization.create', 
          data.id, 
          data.model, 
          state, 
          commit, 
          ORGANIZATION_BALANCE_CREATE_LOADING, 
          ORGANIZATION_BALANCE_CREATE_LOADED, 
          ERRORS_ORGANIZATION_BALANCE
      );
  },
  [ORGANIZATION_BALANCE_EMPTY]({ commit, state }, data) {
      commit(ORGANIZATION_BALANCE_SET_EMPTY);
  }
};

const mutations = {
  [ORGANIZATION_DATATABLE_LOADING_CHANGE](state, data) {
    state.organization_list_loading = data;
  },
  [ORGANIZATION_LOAD_BY_ROLE_CHANGE](state, data) {    
    if (data) {
      state.organization_list_by_role = data.data;
    } else {
      state.organization_list_by_role = [];
    }
  },
  [ORGANIZATION_LOAD_BY_ORGANIZATION_CHANGE](state, data) {    
    if (data) {
      state.organization_list_by_organization = data.data;
    } else {
      state.organization_list_by_organization = [];
    }
  },
  [ORGANIZATION_LOAD_FOR_SMS_TEMPLATE_CHANGE](state, data) {
    if (data) {
      state.organization_list_by_organization = data.data;
    } else {
      state.organization_list_by_organization = [];
    }
  },
  [ORGANIZATION_DATATABLE_CHANGE](state, data) {
    if (data) {
      state.organization_list = data.data;
    } else {
      state.organization_list = [];
    }
  },
  [ORGANIZATION_COMPANY_CHANGE](state, data) {
    if (data) {
      state.organization_company_list = data.data;
    } else {
      state.organization_company_list = [];
    }
  },
  [ORGANIZATION_CHANGE](state, data) {
    state.organization = data;

    if (data) {
      if (state.organization_list) {
        let list = [];
        state.organization_list.forEach(org => {
          if (org.id == data.id) {
            list.push({ ...data });
          } else {
            list.push(org);
          }
        });

        state.organization_list = list;
      }

      if (
        state.organization_current &&
        state.organization_current.id == data.id
      ) {
        state.organization_current = { ...data };
      }
    }
  },
  [ORGANIZATION_LOADING_CHANGE](state, data) {
    state.organization_loading = data;
  },
  [ORGANIZATION_SAVING_CHANGE](state, data) {
    state.organization_saving = data;
  },
  [ORGANIZATION_SAVING_INTERFACE_CHANGE](state, data) {
    state.organization_interface_saving = data;
  },
  [ORGANIZATION_ERRORS_CHANGE](state, data) {
    state.organization_errors = data;
  },
  [ORGANIZATION_ERRORS_INTERFACE_CHANGE](state, data) {
    state.organization_interface_errors = data;
  },
  [ORGANIZATION_CREATING_CHANGE](state, data) {
    state.organization_creating = data;
  },
  [ORGANIZATION_CURRENT_CHANGE](state, data) {
    state.organization_current = data;
  },
  [ORGANIZATION_CURRENT_LOADING_CHANGE](state, data) {
    state.organization_current_loading = data;
  },
  [ORGANIZATION_API_KEY_CHANGE](state, data) {    
    state.organization_api_key = data;
  },
  [ORGANIZATION_API_KEY_LOADING_CHANGE](state, data) {
    state.organization_api_key_loading = data;
  },
  [ORGANIZATION_CREATE_CHANGE](state, data) {
    if (data) {
      if (state.organization_list) {
        state.organization_list.push(data);
      }
    }
  },
  [ORGANIZATION_CREATE_INTERFACE_CHANGE](state, data) {
    if (data) {
        state.organization_interface = data;
      
    }
  },
  [ORGANIZATION_LIST_LOAD_CHANGE](state, data) {
    if (data) {
      state.organizations = data.data;
    }
  },
  [ORGANIZATION_LOAD_FOR_TREE_CHANGE](state, data) {
    if (data) {
      state.organizations_for_tree = data.data;
    }
  },
  [ORGANIZATION_ATTACH_CHANGE](state, data) {
    state.organization_attach_result = data;
  },
  [ORGANIZATION_BALANCE_DATATABLE_LOADING_CHANGE](state, data) {
        state.balance_list_loading = data;
  },
  [ORGANIZATION_BALANCE_DATATABLE_CHANGE](state, data) {
      if(data) {
          state.balance_list        = data.data;
          state.balance_list_total  = data.total;
      }
      else {
          state.balance_list        = [];
          state.balance_list_total  = 0;
      }
  },
  [ORGANIZATION_BALANCE_SET_EMPTY](state) {
      state.balance_list = null;
  },
  [ORGANIZATION_BALANCE_CREATE_LOADING](state, data) {
      state.balance_loading        = data;
  },
  [ORGANIZATION_BALANCE_CREATE_LOADED](state, data) {
      if (data) {
          state.balance_list = null;
      }
  },
  [ERRORS_ORGANIZATION_BALANCE](state, data) {
      state.balance_errors = data;
  },
};

export default {
  state,
  actions,
  mutations
};
